import { css } from 'styled-components';

const scrollBar = css`
  /* custom scrollbar */

  ::-webkit-scrollbar {
    background-color: transparent;
    width: 10px;
  }

  ::-webkit-scrollbar-thumb:hover {
    background-color: ${p => p.theme.scrlthumbhover};
    border-radius: 6px;
    height: 60px;
  }

  ::-webkit-scrollbar-thumb {
    background-color: ${p => p.theme.scrlthumb};
    border-radius: 6px;
    height: 60px;
  }

  /* custom scrollbar */

  input[type='checkbox'] {
    height: 0;
    width: 0;
    visibility: hidden;
  }
`;

export default scrollBar;

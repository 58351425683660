import React from 'react';
import Link from 'gatsby-link';
import { Link as SLink } from 'react-scroll';

const NavItemsSmoothLinks = ({ NavItem }) => (
  <>
    <NavItem>
      <SLink smooth offset={-70} href="/" hashSpy to="home">
        Home
      </SLink>
    </NavItem>
    <NavItem>
      <SLink smooth offset={-100} href="/#about" hashSpy to="about">
        About
      </SLink>
    </NavItem>
    <NavItem>
      <SLink smooth offset={-100} href="/#work" hashSpy to="work">
        Work
      </SLink>
    </NavItem>
    <NavItem>
      <a href="https://art.seelay.in" target="blank">
        Artwork
      </a>
    </NavItem>
    <NavItem>
      <SLink smooth offset={-100} href="/#contact" hashSpy to="contact">
        Contact
      </SLink>
    </NavItem>
  </>
);

const NavItemsGatsbyLinks = ({ NavItem }) => (
  <>
    <NavItem>
      <Link aria-label="Home" href="/" to="/">
        Home
      </Link>
    </NavItem>
    <NavItem>
      <Link aria-label="About" href="/#about" to="/#about">
        About
      </Link>
    </NavItem>
    <NavItem>
      <Link aria-label="Work" href="/#work" to="/#work">
        Work
      </Link>
    </NavItem>
    <NavItem>
      <a
        role="link"
        target="blank"
        aria-label="Seelay Art"
        href="https://art.seelay.in"
      >
        Artwork
      </a>
    </NavItem>
    <NavItem>
      <Link aria-label="Contact" href="/#contact" to="/#contact">
        Contact
      </Link>
    </NavItem>
  </>
);

const NavLinks = React.memo(({ NavItem }) => {
  let path = null;
  if (typeof window !== 'undefined') {
    path = window.location.pathname;
  }

  return (
    <>
      {path === '/' ? (
        <NavItemsSmoothLinks NavItem={NavItem} />
      ) : (
        <NavItemsGatsbyLinks NavItem={NavItem} />
      )}
    </>
  );
});

export default NavLinks;
